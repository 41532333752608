<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                  <el-form-item label="问题ID:" >
                    <el-input style="width: 120px;" v-model="filters.keywordId" @input="(e) => (filters.keywordId = utils.validForbid(e))" clearable placeholder="问题ID搜索"></el-input>
                  </el-form-item>
                    <el-form-item label="关键字:">
                        <el-input style="width: 165px;" v-model="filters.keyword" @input="(e) => (filters.keyword = utils.validForbid(e))" clearable placeholder="所属条文/问题描述"></el-input>
                    </el-form-item>
                    <el-form-item label="所属规范:">
                      <el-select v-model="filters.standardId" style="width: 200px" clearable filterable remote>
                        <el-option v-for="item in filteredStandardOptions" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="规范类型:">
                        <el-select v-model="filters.standardType" clearable>
                            <el-option v-for="item in standardTypeOptions" :key="item.id" :label="item.value"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="问题属性:">
                        <el-select v-model="filters.questionAttribute" clearable>
                            <!-- <el-option v-for="item in questionAttributeArr" :key="item.id" :label="item.value" -->
                            <el-option v-for="item in propertyOptions" :key="item.id" :label="item.value"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                  <el-form-item label="业务类型:">
                    <el-select v-model="filters.filter" clearable multiple style="width: 260px;">
                      <el-option v-for="item in filterArray" :key="item.id" :label="item.value"
                                 :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable" @current-change='currentChange' :highlight-current-row='true'>
            <!-- <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column> -->
            <!-- <el-table-column prop="questionNumber" label="问题编号" align="center" width="180"></el-table-column> -->
            <el-table-column prop="id" label="问题ID" align="center" width="80"></el-table-column>
            <el-table-column prop="drStandardBn" label="所属规范" align="center" width="400">
                <template slot-scope="scope">
                    <!-- 规范名称+空格+规范编号+（版本号+年版） -->
                    <span v-if="scope.row.drStandardVersion">
                        {{scope.row.drStandardName + ' ' + scope.row.drStandardBn + '（' + scope.row.drStandardVersion + '）'}}
                    </span>
                    <span v-else>
                        {{scope.row.drStandardName + ' ' + scope.row.drStandardBn}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="drStandardItemSerialNumber" label="所属条文" align="center" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="questionDescription" label="问题描述" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="ddIdQuestionPropertieValue" label="问题属性" align="center" width="100"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button @click="onReview(scope.row)" type="text" size="small">预览</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新建弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="addDialogShow" v-model="addDialogShow"
            :close-on-click-modal="false" width='50%'>
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px" style='min-width="800px"'>
                <!-- <el-form-item label="问题编号:" prop="questionNumber">
                    <el-input v-model="addForm.questionNumber"></el-input>
                </el-form-item> -->
                <el-form-item label="问题描述:" prop="questionDescription">
                    <el-input style="width:80%" type="textarea" v-model="addForm.questionDescription"
                        :rows="4"></el-input>
                </el-form-item>
                <el-form-item label="所属规范:" prop="drStandardId">
                  <el-select v-model="addForm.drStandardId" @change="changeStandard" style="width:80%" clearable filterable remote>
                    <el-option v-for="item in filteredStandardOptions" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              <el-form-item label="问题属性:" prop="ddIdQuestionPropertie">
                <el-select style="width:80%" v-model="addForm.ddIdQuestionPropertie">
                  <el-option v-for="item in propertyOptions" :key="item.id" :label="item.value"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="问题分类:" prop="ddIdQuestionType">
                <el-select style="width:80%" v-model="addForm.ddIdQuestionType">
                  <el-option v-for="item in typeOptions" :key="item.id" :label="item.value"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="所属条文:" prop="drStandardItemArr">
                    <el-tooltip class="tooltip" effect="dark" :content="tooltipContent" placement="bottom">
                        <el-cascader clearable style="width:80%" @mouseenter.native="showStandardItem" filterable
                             v-model="addForm.drStandardItemArr" @change="changeStandardItem"
                            :options="standardItemOptions"
                            :props="{ value: 'id', label: 'bn', children: 'children', checkStrictly: true, level: 0 }">
                            <template slot-scope="{ node, data }">
                                <el-tooltip class="tooltip" effect="dark"
                                    :content="node.level === 1 ? data.bn : data.content" placement="bottom">
                                    <span v-if="node">{{ data.bn }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="条文关联注:">
                    <el-tooltip class="tooltip" effect="dark" :content="noteTooltip" placement="bottom">
                        <el-cascader clearable style="width:80%" @mouseenter.native="showNote" :show-all-levels="false"
                            v-model="addForm.notesArr" @change="changeStandardItemNotes" :options="notesOptions"
                            :props="{ value: 'id', label: 'bn', children: 'children', checkStrictly: true , level: 0 }">
                            <template slot-scope="{ node, data }">
                                <el-tooltip class="tooltip" effect="dark"
                                    :content="node.level === 1 ? data.title : data.content" placement="bottom">
                                    <span v-if="node">{{ data.bn }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="业务类型:">
                  <template>
<!--                    <el-radio-group v-model="addForm.ddIdNodeBizType">-->
<!--                      <el-radio :label="111">图审</el-radio>-->
<!--                      <el-radio :label="253">验收</el-radio>-->
<!--                      <el-radio :label="0">图审+验收</el-radio>-->
<!--                    </el-radio-group>-->
	                  <el-checkbox-group v-model="addForm.ddIdNodeBizType">
		                  <el-checkbox :label="1">审查</el-checkbox>
		                  <el-checkbox :label="10">验收</el-checkbox>
		                  <el-checkbox :label="100">开业检查</el-checkbox>
	                  </el-checkbox-group>
                  </template>
                </el-form-item>
<!--	            <el-form-item label="AI:">-->
<!--			            <template>-->
<!--				            <el-radio-group v-model="isOrRinght" @input="changeAi">-->
<!--					            <el-radio :label="1">是</el-radio>-->
<!--					            <el-radio :label="2">否</el-radio>-->
<!--				            </el-radio-group>-->
<!--			            </template>-->
<!--	            </el-form-item>-->
                <!-- <el-form-item label="审查内容:" prop="drAuditContentArr">
                    <el-select @change="changeAudit" filterable style="width:80%" v-model="addForm.drAuditContentArr" multiple>
                        <el-option v-for="item in auditContentIdOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogClose">取消</el-button>
                <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="reviewShow" title="预览" v-model="reviewShow" :close-on-click-modal="false" width='50%'>
            <div>
                <el-form label-width="120px">
                    <el-form-item label="问题ID：">
                        {{ detail.id }}
                    </el-form-item>
                    <el-form-item label="问题描述：">
                        {{ detail.questionDescription }}
                    </el-form-item>
                    <el-form-item label="所属规范：">
                        <span v-if="detail.drStandardVersion">
                            {{
                                detail.drStandardName + ' ' + detail.drStandardBn + '(' + detail.drStandardVersion + ')'
                            }}
                        </span>
                        <span v-else>
                            {{
                                detail.drStandardName + ' ' + detail.drStandardBn
                            }}
                        </span>
                    </el-form-item>
                    <el-form-item label="所属条文：">
                        {{ detail.drStandardItemSerialNumber }}
                        {{detail.drStandardItemSerialContent}}
                    </el-form-item>
                    <el-form-item label="条文关联注：">
                        {{ detail.drStandardItemNotesNumber }}
                        {{ detail.drStandardItemNotesContent }}
                    </el-form-item>
                    <el-form-item label="审查内容：">
                        <span v-for="(item, index) in detail.drAuditContentListId" :key="index">
                            {{ item.name }}
                            <span v-if="index !== detail.drAuditContentListId.length - 1">,</span>
                        </span>
                    </el-form-item>
                    <el-form-item label="问题属性：">
                        {{ detail.ddIdQuestionPropertieValue }}
                    </el-form-item>
                    <el-form-item label="问题分类：">
                        {{ detail.ddIdQuestionTypeValue }}
                    </el-form-item>
                  <el-form-item label="业务类型：">
                    <template>
<!--                        <div v-if="splitNumberToArray(detail.ddIdNodeBizType).includes(1) " >审查</div>-->
<!--                        <div v-if="detail.ddIdNodeBizType.includes(10)" >验收</div>-->
<!--                        <div v-if="detail.ddIdNodeBizType.includes(100)" >开业检查</div>-->
	                    <el-checkbox-group v-model="detail.ddIdNodeBizType">
		                    <el-checkbox disabled :label="1">审查</el-checkbox>
		                    <el-checkbox disabled :label="10">验收</el-checkbox>
		                    <el-checkbox disabled :label="100">开业检查</el-checkbox>
	                    </el-checkbox-group>
                    </template>
                  </el-form-item>
<!--	                <el-form-item label="AI:">-->
<!--		                <template>-->
<!--			                <el-radio-group v-model="detail.isAi">-->
<!--				                <el-radio disabled :label="1">是</el-radio>-->
<!--				                <el-radio disabled :label="2">否</el-radio>-->
<!--			                </el-radio-group>-->
<!--		                </template>-->
<!--	                </el-form-item>-->
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="reviewShow = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
    getQuestionPageList,
    getQuestionById,
    getAuditContentAllList,
    getDrStandardItemList,
    getAllDrStandardList,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    getByNamespace,
    getDrStandardItemNotesList,
} from '@/api/api'
export default {
    components: { Toolbar },
    data() {
        return {
            utils: '',
            buttonList: [],
            needData: '',
            filters: {
                keyword: undefined,
                keywordId: undefined,
                standardId: undefined,
                standardType: undefined,
                questionAttribute: undefined,
                filter:[],
            },
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 新建
            addDialogShow: false,
            addLoading: false,
            reviewShow: false,
            dialogTitle: '',
            tooltipContent: '',
            noteTooltip: '',
	        choiceStandardItemId: undefined,
            // form
            addForm: {
                id: undefined,
                // questionNumber: '',
                questionDescription: '',
                drStandardId: undefined,
                drStandardItemId: undefined,
                drStandardItemListId: undefined,
                drStandardItemNotesId: undefined,
                notesId: undefined,
                drAuditContentListId: undefined,
                ddIdQuestionPropertie: undefined,
                ddIdQuestionType: undefined,
                drStandardItemArr: [],
                // drAuditContentArr: [],
                notesArr: [],
                ddIdNodeBizType:[],
	            // isAi:null,
            },
            addRules: {
                // questionNumber: [{ required: true, message: '请输入问题编号', trigger: 'blur' }],
                questionDescription: [{ required: true, message: '请输入问题描述', trigger: 'blur' }],
                drStandardId: [{ required: true, message: '请选择所属规范', trigger: 'change' }],
                drStandardItemArr: [{ required: true, message: '请选择所属条文', type: 'array', trigger: 'change' }],
                // drAuditContentArr: [{ required: true, message: '请选择审核对象', type: 'array', trigger: 'change' }],
                ddIdQuestionPropertie: [{ required: true, message: '请选择问题属性', trigger: 'change' }],
                ddIdQuestionType: [{ required: true, message: '请选择问题分类', trigger: 'change' }],
            },
            detail: {},
            // options
            propertyOptions: [], //问题属性
            typeOptions: [], //问题类型
            standardItemOptions: [], //条文
            standardTypeOptions: [], //规范类型
            filteredStandardOptions: [],// 过滤后的规范选项数据
            notesOptions: [], //注
            standardOptions: [], //规范
            auditContentIdOptions: [], //审查内容
            // 问题属性
            questionAttributeArr: [
                {id: 0,value: "应"},
                {id: 1,value: "强"},
                {id: 2,value: "可"},
                {id: 3,value: "宜"},
            ],
          filterArray:[
            {id: 1,value: "审查"},
            {id: 10,value: "验收"},
            {id: 100,value: "开业检查"},
          ],
	        isOrRinght:null,
        }
    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.init();
    },
	watch: {
			'isOrRinght':function(val){
				console.log(val,'val')
				// this.addForm.isAi = val
				// console.log(this.addForm.isAi,'isaisssss');
			}
},
    methods: {
	    changeAi(value){
		    console.log(value,'valueddddd')
		    this.isOrRinght = value
	    },
        init() {
            this.getDataList();
            // 获取类型
            getByNamespace({ namespace: 'BizService.DrAuditRep.StandardType' }).then(res => {
                if (res.data.success) {
                    this.standardTypeOptions = res.data.response;
                }
            })
            getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionType' }).then(res => {
                if (res.data.success) {
                    this.typeOptions = res.data.response;
                }
            })
            getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionPropertie' }).then(res => {
                if (res.data.success) {
                    this.propertyOptions = res.data.response;
                }
            })
            this.getAuditContentFun()

            // 获取所有规范
            getAllDrStandardList().then(res => {
                if (res.data.success) {
                    let options = []
                    res.data.response && res.data.response.forEach(item => {
                        let obj = {
                            id: item.id,
                            name: `${item.name} ${item.bn}(${item.version})`
                        }
                        options.push(obj);
                    });
                    this.standardOptions = options;
					this.filteredStandardOptions = this.standardOptions;
                }
            })

        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        getListData(){
          this.pages.pageIndex=1;
          this.getDataList()
        },
        // 列表查询接口
        getDataList() {
            let params = {
                pageSize: this.pages.pageSize,
                pageIndex: this.pages.pageIndex,
                keyword: this.filters.keyword,
              questionId:this.filters.keywordId,
                standardId: this.filters.standardId,
                standardType: this.filters.standardType,
                questionPropertie: this.filters.questionAttribute,
              ddIdNodeBizType:undefined,
              sourceType:'web',
            }
            if(this.filters.filter&&this.filters.filter.length>1){
              params.ddIdNodeBizType = this.arraySum(this.filters.filter)
            }
            if (this.filters.filter && this.filters.filter.length === 1) {
              params.ddIdNodeBizType = this.filters.filter[0]
            }
            this.listLoading = true
            getQuestionPageList(params).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.response.data;
                    this.pages.dataCount = res.data.response.dataCount;
                }
                this.listLoading = false
            })
        },
        // 获取审查内容
        getAuditContentFun() {
            getAuditContentAllList().then(res => {
                if (res.data.success) {
                    this.auditContentIdOptions = res.data.response;
                }
            })
        },
	      //数组求和
		    arraySum(arr) {
			    return arr.reduce((sum, num) => sum + num, 0);
		    },
        // 新建
        handleAdd() {
            this.addDialogShow = true;
            this.addLoading = false;
            this.dialogTitle = '新增';
            this.addForm = {
                id: undefined,
                // questionNumber: '',
                questionDescription: '',
                drStandardId: undefined,
                drStandardItemId: undefined,
                drStandardItemListId: undefined,
                drStandardItemNotesId: undefined,
                notesId: undefined,
                drAuditContentListId: undefined,
                ddIdQuestionPropertie: undefined,
                ddIdQuestionType: undefined,
                drStandardItemArr: [],
                ddIdNodeBizType:[],
	            isAi: null,
                // drAuditContentArr: []
            }
            this.getAuditContentFun()
        },
        // 预览问题
        async onReview(row) {
            let data = JSON.parse(JSON.stringify(row))
            this.detail = data;
	        console.log(data,'data')
	        // this.detail.ddIdNodeBizType = []
          //   console.log(data);
            this.detail.drAuditContentListId = JSON.parse(data.drAuditContentListId);

            let res = await getQuestionById({id:row.id})
            this.detail.drStandardItemSerialNumber = res.data.response.drStandardItemSerialNumber||'';
            this.detail.drStandardItemNotesNumber = res.data.response.drStandardItemNotesNumber||'';
            this.detail.drStandardItemSerialContent = res.data.response.drStandardItemSerialContent||'';
            this.detail.drStandardItemNotesContent = res.data.response.drStandardItemNotesContent||'';
		        if (data&&data.ddIdNodeBizType===111||data.ddIdNodeBizType === 11||data.ddIdNodeBizType ===110||data.ddIdNodeBizType ===101){
			        this.detail.ddIdNodeBizType=this.splitNumberToArray(data.ddIdNodeBizType)
		        }
		        if (data&&data.ddIdNodeBizType===1||data.ddIdNodeBizType === 10 ||data.ddIdNodeBizType === 100){
			        this.detail.ddIdNodeBizType=[data.ddIdNodeBizType]
		        }
						// this.detail.isAi = data.isAi?1:2
            this.reviewShow = true;
            // console.log(this.detail);
        },
        // 新增弹窗关闭
        addDialogClose() {
          this.$refs.addFormRef.resetFields();
            this.addDialogShow = false;
            this.addForm = {
                id: undefined,
                // questionNumber: '',
                questionDescription: '',
                drStandardId: undefined,
                drStandardItemId: undefined,
                drStandardItemListId: undefined,
                drStandardItemNotesId: undefined,
                notesId: undefined,
                drAuditContentListId: undefined,
                ddIdQuestionPropertie: undefined,
                ddIdQuestionType: undefined,
                drStandardItemArr: [],
                // drAuditContentArr: []
                notesArr: [],
                ddIdNodeBizType: [],
	              // isAi: undefined,
            }

        },
		    splitNumberToArray(num) {
			    let numStr = num.toString();
			    let length = numStr.length;
			    let result = [];

			    for (let i = 0; i < length; i++) {
				    let digit = Number(numStr[i]);
				    let placeValue = digit * Math.pow(10, length - i - 1);
				    result.push(placeValue);
			    }

			    return result;
		    },
        // 编辑
        async handleEdit() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            const res = await getQuestionById({ id: this.needData.id })
            if (res.data.success) {
                const { response: data } = res.data;
                this.addDialogShow = true;
                this.addLoading = false;
                this.dialogTitle = '编辑';
	            console.log(data,'data,,,,,')
                this.addForm = {
                    id: this.needData.id,
                    // questionNumber: data.questionNumber,
                    questionDescription: data.questionDescription,
                    ddIdQuestionPropertie: data.ddIdQuestionPropertie,
                    ddIdQuestionType: data.ddIdQuestionType,
                    drStandardId: data.drStandardId,
                    drStandardItemId: data.drStandardItemId,
                    drStandardItemListId: data.drStandardItemListId,
                    drAuditContentListId: data.drAuditContentListId,
	                   // isAI:undefined,
                    ddIdNodeBizType: [],
	                
                }
								// this.addForm.isAi = data.isAi?1:2;
                if (data&&data.ddIdNodeBizType===111||data.ddIdNodeBizType === 11||data.ddIdNodeBizType ===110||data.ddIdNodeBizType ===101){
                  this.addForm.ddIdNodeBizType=this.splitNumberToArray(data.ddIdNodeBizType)
                }
                if (data&&data.ddIdNodeBizType===1||data.ddIdNodeBizType === 10 ||data.ddIdNodeBizType === 100){
                  this.addForm.ddIdNodeBizType.push(data.ddIdNodeBizType)
                }

                // 审查内容赋值
                let auditIds = [];
                let ids = JSON.parse(data.drAuditContentListId);
                ids.forEach(item => {
                    auditIds.push(item.id)
                })
                // 多选框赋值，不然会卡住
                // this.$set(this.addForm, 'drAuditContentArr', auditIds)

                // 条文赋值
                await this.changeStandard(data.drStandardId);
                let arr = [];
                let idsList = JSON.parse(data.drStandardItemListId);
				// 兼容条文数据存储错误导致页面渲染不正确
				if(idsList.length == 1)
				{
					let tmpDrStandardItemListId = idsList[0];
					let tmpList = [];
					let drStandardItemSerialNumber = data.drStandardItemSerialNumber.replace(tmpDrStandardItemListId.bn + "条","");

					if(drStandardItemSerialNumber)
					{
						let matchValue = drStandardItemSerialNumber.match(/第\d款/i);
						if(matchValue&&matchValue.length > 0)
						{
							drStandardItemSerialNumber = drStandardItemSerialNumber.replace(matchValue[0],"");
							matchValue[0] = matchValue[0].replace("第","").replace("款","");

							tmpDrStandardItemListId = JSON.parse(tmpDrStandardItemListId.content);

							tmpDrStandardItemListId[0].children.forEach(function(e){
								if(e.bn == matchValue[0])
								{
									tmpList = e.children;
									delete e.children;
									idsList.push(e);
								}
							});
							tmpDrStandardItemListId = tmpList;
						}
					}


					if(drStandardItemSerialNumber)
					{
						tmpList = [];
						let matchValue2 = drStandardItemSerialNumber.match(/第\d项/i);
						if(matchValue2&&matchValue2.length > 0)
						{
							drStandardItemSerialNumber = drStandardItemSerialNumber.replace(matchValue2[0],"");
							matchValue2[0] = matchValue2[0].replace("第","").replace("项","");
							tmpDrStandardItemListId.forEach(function(e){
								if(e.bn == matchValue2[0])
								{
									tmpList = e.children;
									delete e.children;
									idsList.push(e);
								}
							});
							tmpDrStandardItemListId = tmpList;
						}
					}

					if(drStandardItemSerialNumber)
					{
						tmpList = [];
						let matchValue3 = drStandardItemSerialNumber.match(/第\d目/i);
						if(matchValue3&&matchValue3.length > 0)
						{
							drStandardItemSerialNumber = drStandardItemSerialNumber.replace(matchValue3[0],"");
							matchValue3[0] = matchValue3[0].replace("第","").replace("目","");
							tmpDrStandardItemListId.forEach(function(e){
								if(e.bn == matchValue3[0])
								{
									tmpList = e.children;
									delete e.children;
									idsList.push(e);
								}
							});
							tmpDrStandardItemListId = tmpList;
						}
					}
					this.addForm.drStandardItemListId = JSON.stringify(idsList);
				}
				// console.log(idsList);
                idsList.forEach(item => {
                    arr.push(item.id)
                })
                this.$set(this.addForm, 'drStandardItemArr', arr)
                // this.addForm.drStandardItemArr = arr;

                // 条文注释赋值
                await this.getNotes(arr[arr.length - 1]);
                // this.addForm.drStandardItemNotesId = data.drStandardItemNotesId;
                this.$set(this.addForm, 'drStandardItemNotesId', data.drStandardItemNotesId)
                if(data.drStandardItemNotesId && data.drStandardItemNotesId.length > 0){
                    let note = JSON.parse(data.drStandardItemNotesId)
                    let notesArr = [note.id];
                    // this.addForm.notesArr = [note.id];
                    if (note.children && note.children.length > 0) {
                        notesArr.push(note.children[0].id)
                    }
                    this.$set(this.addForm, 'notesArr', notesArr)
                }
                this.$refs.addFormRef.clearValidate();
                console.log(this.addForm);
            }
        },
        // 级联查看条文内容
        showStandardItem() {
            if (!this.addForm.drStandardItemListId) {
                return
            } else {
                let list = JSON.parse(this.addForm.drStandardItemListId);
                let obj = list[list.length - 1];
                if (obj && obj.parentId === 0) {
                    this.tooltipContent = obj?.bn;
                } else if (obj) {
                    this.tooltipContent = obj?.bn + ' ' + obj?.content;
                } else {
                    this.tooltipContent = '';
                }
            }

        },
        // 级联查看注
        showNote() {
            if (!this.addForm.drStandardItemNotesId) {
                this.noteTooltip = '';
                return
            } else {
                let list = JSON.parse(this.addForm.drStandardItemNotesId);
                if (list.content === '') {
                    this.noteTooltip = list.bn;
                } else if (!list) {
                    this.noteTooltip = '';
                } else {
                    this.noteTooltip = list.children?.[0].bn + ' ' + list.children?.[0].content;
                }
            }
        },
        // 选择规范
        changeStandard(data) {
            getDrStandardItemList({
                pageSize: 1000,
                pageIndex: 1,
                standardId: data
            }).then(res => {
                if (res.data.success) {
                    this.standardItemOptions = res.data.response.data;
                    this.standardItemOptions.forEach((item, index) => {
                        let obj = JSON.parse(item.content);
                        item.children = obj[0].children;
                        item.disabled = true
                    })
                    this.removeChildren(this.standardItemOptions);
                }
            })
        },
        // 把children为空的去掉
        removeChildren(list) {
            list.forEach(item => {
                if(item.children && item.children.length>0){
                    this.removeChildren(item.children)
                }else if(item.children.length === 0){
                    item.children = undefined;
                }else{
                    item.children = undefined;
                }
            })
        },
        // 选择条文子项
        changeStandardItem(data) {
	        console.log(data,'选择条文子项')
            if (!data) return
            // 获取当前选择的条文关联注
            this.getNotes(data[data.length - 1]);
            // 条文id
            this.addForm.drStandardItemId = data[0];
						this.choiceStandardItemId = data[data.length - 1];
            let list = []
            // 树形结构扁平化
            const treeList = this.treeToList(this.standardItemOptions)
            for (let i = 0; i < data.length; i++) {
                // 筛选选中条文id的数据
                let obj = treeList.filter(item => {
                    return item.id === data[i]
                })
                let formobj = {
                    id: obj[0].id,
                    bn: obj[0].bn,
                    content: obj[0].content,
                    typeId: obj[0].typeId,
                    parentId: obj[0].parentId,
                    standardId: obj[0]?.standardId,
                }
                list.push(formobj)
            }

            this.addForm.drStandardItemListId = JSON.stringify(list);
        },
        treeToList(list) {
            let res = []
            for (const item of list) {
                const { children, ...i } = item
                if (children && children.length) {
                    res = res.concat(this.treeToList(children))
                }
                res.push(i)
            }
            return res
        },
        changeAudit(data) {
            let ids = []
            for (let k in data) {
                let res = this.auditContentIdOptions.filter(item => {
                    return data[k] === item.id
                })
                ids.push(res[0]);
            }
            this.addForm.drAuditContentListId = JSON.stringify(ids);
        },
        // 选择注
        changeStandardItemNotes(data) {
            // console.log(data);
            if (data && data.length > 0) {
                const list = this.treeToList(this.notesOptions)
                // console.log(list);
                let resParent = list.filter(item => {
                    return Number(data[0]) === Number(item.id)
                })
                let obj = {
                    ...resParent[0]
                }
                if (data&&data.length > 1) {
                    let res = list.filter(item => {
                        return data[1] === item.id
                    })
                    obj.children = res;
                }

                this.addForm.drStandardItemNotesId = JSON.stringify(obj);
            } else {
                this.addForm.drStandardItemNotesId = undefined;
            }

        },
        getNotes(id) {
            getDrStandardItemNotesList({ standardItemId: id }).then(res => {
                if (res.data.success) {
                    let list = [];
                    res.data.response && res.data.response.forEach(item => {
                        let obj = {
                            bn: item.bn,
                            title: item.title,
                            id: item.id,
                            picList: item.picList,
                            drStandardItemId: item.drStandardItemId
                        }
                        if (item.content !== '') {
                            obj.children = JSON.parse(item.content)
                        } else {
                            obj.content = ''
                            obj.children = []
                        }
                        list.push(obj);
                    })
                    this.notesOptions = list;
                    // console.log(this.notesOptions);
                }
            })
        },
        // 新建提交
        addSubmit() {
            this.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    this.addLoading = true
	                let params = {
		                ...this.addForm,
	                }
									params.choiceStandardItemId=this.choiceStandardItemId
	                if (this.addForm.ddIdNodeBizType && this.addForm.ddIdNodeBizType.length > 1) {
		                params.ddIdNodeBizType = this.arraySum(this.addForm.ddIdNodeBizType)
	                }
	                if (this.addForm.ddIdNodeBizType && this.addForm.ddIdNodeBizType.length === 1) {
		                params.ddIdNodeBizType = this.addForm.ddIdNodeBizType[0]
	                }
                    if (this.addForm.id === undefined) {
                        addQuestion(params).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false
                        })
                    } else {
                        updateQuestion(params).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                              this.$refs.addFormRef.resetFields();
                            }
                            this.addLoading = false
                        })
                    }

                }
            })
        },
        handleDel() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.$confirm('确认删除?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteQuestion({ id: this.needData.id }).then(res => {
                    if (res.data.success) {
                        this.$message({
                            message: res.data.message,
                            type: "success"
                        });
                        this.getDataList();
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                })
            })
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function (row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
                ? ""
                : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
.el-tooltip__popper {
    max-width: 600px;
}
</style>
